import { IAddressInputAddress } from '@opendoor/cinderblocks/growth/AddressInput/AddressInput';
import { OdProtosSellReceptionData_SellerInput_AnalyticsMetadata_Product } from '__generated__/athena';

import { CONSUMER_FE_URL } from '../globals';

import { analytics, globalObservability } from '@opendoor/observability/slim';
import * as api from './';
import { getAnonymousId } from '../shared/trackers';

const Sentry = globalObservability.getSentryClient();

interface IPathParams {
  token: string;
  page: string;
  inApp?: boolean;
}

const sellerGlobalPagePath = ({ token, page, inApp = false }: IPathParams) => {
  let path = `/seller-lead/${token}/p/${page}`;
  if (inApp) {
    path = `${path}?app=1`;
  }
  return path;
};

export const bffSellerGlobalPagePath = ({ token, page, inApp = false }: IPathParams) => {
  return CONSUMER_FE_URL + sellerGlobalPagePath({ token, page, inApp });
};

export const getSellerFlowUUID = () => {
  const fragments = window.location.search.match(/sfuuid=([-\w]+)/);
  if (!fragments) {
    return undefined;
  }
  return fragments[1];
};

export const getUrlParam = (paramName: string) => {
  const regExp = new RegExp(`${paramName}\\=([\\w-_]+)`);
  const fragments = window.location.search.match(regExp);
  if (!fragments) {
    return undefined;
  }
  return fragments[1];
};

export const getSellerFlowName = () => {
  return getUrlParam('sfname');
};

export const getCovid19Whitelist = () => {
  return getUrlParam('covid19_whitelist') === 'true';
};

export const getCustomExperimentEntityId = () => {
  return getUrlParam('experiment_entity_id');
};

export const getChannelOverride = () => {
  return getUrlParam('channel_override')?.toUpperCase();
};

export const startAddressVerification = async ({
  address,
  product,
  channel,
  trackingKeywords,
  inputLocation,
  onSuccess,
}: {
  address: IAddressInputAddress;
  product: OdProtosSellReceptionData_SellerInput_AnalyticsMetadata_Product;
  channel?: string;
  trackingKeywords?: string;
  inputLocation?: string;
  onSuccess?: (token: string, pdpUrl?: string) => void;
}) => {
  analytics.trackEvent('inputting', 'address', 'verifying', {
    ...address,
    inputLocation,
  });

  const addressEntry = {
    product,
    url: window.location.href,
  };

  const options = {
    channel: getChannelOverride() || channel,
    covid19Whitelist: getCovid19Whitelist(),
    experiment_entity_id: getCustomExperimentEntityId() || getAnonymousId(),
    seller_flow_name_override: getSellerFlowName(),
    seller_flow_uuid_override: getSellerFlowUUID(),
    trackingKeywords,
  };
  const error = await api
    .createSellerInput(address, addressEntry, options)
    .then(
      ({
        data: {
          reception: {
            createSellerInputWithTrackingDataFromCookies: {
              sellerInput: {
                uuid,
                pdpUrl,
                address: { postalCode },
              },
            },
          },
        },
      }) => {
        analytics.register({ 'Offer ID': uuid, 'Offer Postal Code': postalCode });
        onSuccess && onSuccess(uuid, pdpUrl);
      },
    )
    .catch((e) => {
      analytics.trackEvent('lead', 'failed');
      Sentry.captureException?.(e);
      return e;
    });

  return error;
};
