// helper code for no-code landing pages
import { IIdToLoaderData } from 'cms/loaders';
import { Asset } from 'contentful';
import { proxyUrl } from './imgixUrl';

export type PreloadImages = Array<{
  url: string;
  media: string;
}>;

// when a no-code landing page needs to preload an image for performance reasons,
// it can include a `preloadImages` field in the loader data
// this function crunches the loader data and pulls out all the preload images
// so that the top-level page can include those preload urls in the Head section
export const getPreloadsFromLoaderData = (idToLoaderData: IIdToLoaderData) => {
  return Object.values(idToLoaderData)
    .map((loaderData: any) => {
      return loaderData.preloadImages;
    })
    .filter((preload: any) => preload)
    .flat();
};

// transform contentful image urls into imgix-proxied urls
// this will only work server-side, since the imgix token is
// not exposed to the client
export const proxyContentfulImage = (image: Asset) => {
  return proxyUrl(`https:${image.fields.file.url}`);
};
