import fromPairs from 'lodash/fromPairs';
import { ImageLoaderProps } from 'next/image';
import ImgixClient from '@imgix/js-core';

const IMGDROP_URL = 'imgdrop.imgix.net';
const PROXY_URL = process.env.IMGIX_DOMAIN || 'check 1pass for local env setup';

const imgdropClient = new ImgixClient({
  domain: IMGDROP_URL,
});

const parseFileAndParamsFromUrl = (url: string) => {
  const [imageUrl, imgixParamsString] = url.split('?');

  let imgixParams: Record<string, string> = {};
  if (imgixParamsString) {
    imgixParams = fromPairs(imgixParamsString.split('&').map((param) => param.split('=')));
  }

  return { imageUrl, imgixParams };
};

// applies default parameters and calls the imgix client to generate the finalized url
const generateImgixUrl = (imageUrl: string, imgixClient: ImgixClient, imgixParams = {}) => {
  const defaultImgixParams = {
    auto: 'format,compress', // reduce size, increase page speed, use most efficient image format
    ch: 'Width,DPR', // client hinting, where available
    dpr: '2', // 2x display for retina screens by default,
    fit: 'max', // use the `w` and `h` params as maximums
    q: '50', // no visible diff between 50 and 100
  };

  const defaultsAndParams = { ...defaultImgixParams, ...imgixParams };

  return imgixClient.buildURL(imageUrl, defaultsAndParams);
};

// a custom imgixLoader, to handle url generation for next/images.
// their default imgix loader doesn't apply auto=compress or client hints :(
export function imgixLoader({ src, width, quality }: ImageLoaderProps): string {
  const { imageUrl, imgixParams } = parseFileAndParamsFromUrl(src);

  imgixParams['w'] = `${width}`;
  imgixParams['q'] = `${quality}` || '50';

  return generateImgixUrl(imageUrl, imgdropClient, imgixParams);
}

export const proxyUrl = (imageUrl: string) => {
  // keep this inside the function to make sure it doesn't get bundled into the frontend ever
  // since we need to hide the token
  const proxyClient = new ImgixClient({
    domain: PROXY_URL,
    secureURLToken: process.env.IMGIX_SECURITY_TOKEN ?? '<add the token to your env>',
  });

  return generateImgixUrl(imageUrl, proxyClient);
};

export default function imgixUrl(imgixFileAndParams: string): string {
  const { imageUrl, imgixParams } = parseFileAndParamsFromUrl(imgixFileAndParams);

  return generateImgixUrl(imageUrl, imgdropClient, imgixParams);
}
