/* storybook-check-ignore */
import styled from '@emotion/styled';

import { colors } from '@opendoor/bricks/theme/eero'; // TODO: change to novo
import { Box, Heading, Image, Button } from '@opendoor/bricks/core';
import { useObservability } from '../../helpers/observability';
import { ILpComponentDisplay } from 'declarations/contentful';
import { Awaited, EntryComponent } from 'cms/entries/entries';
import { Asset, Entry } from 'contentful';
import { proxyContentfulImage } from '../../helpers/landingPages';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import typography from '../../components/landing-pages/typography';

export type IDisplayLoaderReturn = {
  desktopImageUrl: string;
  imageAlt: string;
};

const displayLoader = async (input: ILpComponentDisplay, _root?: Entry<any>) => {
  const desktopImageUrl = proxyContentfulImage(
    input.fields.lpComponentImageOverlay?.fields.desktopImage as Asset,
  );
  const imageAlt = input.fields.lpComponentImageOverlay?.fields.imageAltText as string;

  const loaderReturn: IDisplayLoaderReturn = {
    desktopImageUrl,
    imageAlt,
  };

  return loaderReturn;
};

const Section = styled.section`
  background-color: ${colors.blue30};
`;

const Container = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 960px;
  height: 400px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
    height: initial;
    max-width: 90%;
  }
`;

const ContentContainer = styled.div`
  padding: 48px 0 0 0;
  max-width: 450px;
  display: inline-block;

  @media (max-width: 991px) {
    padding: 32px 0;
    width: 50%;
  }

  @media (max-width: 767px) {
    padding: 40px 0;
    width: 100%;
  }

  @media (max-height: 568px) {
    margin: 10px 5% 40px 5%;
  }
`;

const SubHeadline = styled.p`
  font-size: 16px;
  line-height: 1.4;
  margin-top: 20px;

  @media (max-width: 767px) {
    margin-top: 16px;
    padding: 0 10px;
    text-align: center;
  }
`;

const ButtonContainer = styled.div`
  height: 50px;
  margin-top: 32px;
  margin-bottom: 12px;

  @media (max-width: 991px) {
    margin-top: 24px;
  }
`;

const RenderDisplay = (
  entry: ILpComponentDisplay,
  resolvedData?: Awaited<ReturnType<typeof displayLoader>>,
) => {
  const { trackEvent } = useObservability();

  const { fields } = entry;
  const header = documentToReactComponents(fields.header);
  const body = fields?.description;

  const imageUrl = resolvedData?.desktopImageUrl;
  const imageAlt = resolvedData?.imageAlt;

  return (
    <Section id="buyer-panel-container" className="buyer-container">
      <Container>
        <ContentContainer>
          <Heading
            size="UNSAFE_h4"
            as="h4"
            textAlign={['center', null, 'initial', 'initial']}
            {...typography.h4}
          >
            {header}
          </Heading>
          <SubHeadline className="sub-head">{body}</SubHeadline>
          <ButtonContainer>
            <Box mx={['auto', null, 'initial', 'initial']} width={['80%', null, '40%', '40%']}>
              <Button
                aria-label=""
                analyticsName="cosmos-landing-pages-FAQs-opendoor-complete"
                data-testid="learn-more-about-opendoor-complete"
                /** @ts-expect-error TODO @growth Remove border radius override */
                borderRadius="rounded"
                size="md"
                marginTop="10px"
                onClick={() => {
                  // TODO: remove categoryOverride
                  // observability is initialized with 'cosmos' as the category for all events
                  trackEvent('cta-click', 'buying-info', {}, { categoryOverride: 'homepage' });
                  window.location.href = '/buy-and-sell';
                }}
              >
                Learn more
              </Button>
            </Box>
          </ButtonContainer>
        </ContentContainer>
        <Box
          width={['250px', null, '300px', '300px']}
          alignSelf={['center', null, 'flex-end', 'flex-end']}
        >
          <Image src={imageUrl} alt={imageAlt || ''} loading="lazy" />
        </Box>
      </Container>
    </Section>
  );
};

const Display: EntryComponent<ILpComponentDisplay, Awaited<ReturnType<typeof displayLoader>>> = {
  render: RenderDisplay,
  loader: displayLoader,
};

export default Display;
