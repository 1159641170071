import IEntryLookup from './entries';
import Hero from '../../components/landing-pages/Hero';
import SocialProofOdometer from '../../components/landing-pages/SocialProofOdometer';
import Testimonials from '../../components/landing-pages/Testimonials';
import Display from '../../components/landing-pages/Display';
import Steps from '../../components/landing-pages/Steps';
import FAQs from '../../components/landing-pages/FAQs';
import Press from '../../components/landing-pages/Press';
import SellingOptions from '../../components/landing-pages/SellingOptions';
import VideoHero from '../../components/landing-pages/VideoHero';
import TextSection from '../../components/landing-pages/TextSection';
import HeroCard from '../../components/landing-pages/HeroCard';
import MapBackgroundHero from '../../components/landing-pages/MapBackgroundHero';
import CarouselCards from '../../components/landing-pages/CarouselSection/CarouselCards';
import ImageCard from '../../components/landing-pages/ImageCard';
import FAQsDisplay from '../../components/landing-pages/FAQsDisplay';

const landingPageEntryLookup: IEntryLookup = {
  block: {
    lpComponentHero: Hero,
    lpComponentPress: Press,
    lpComponentSteps: Steps,
    lpComponentOdometer: SocialProofOdometer,
    lpComponentTestimonials: Testimonials,
    lpComponentSellingOptions: SellingOptions,
    lpComponentDisplay: Display, // This is using a generic display component
    lpComponentModal: FAQs, // Using generic modal component
    lpComponentVideoHero: VideoHero,
    lpComponentTextSection: TextSection,
    lpComponentHeroCard: HeroCard,
    lpComponentMapBackgroundHero: MapBackgroundHero,
    lpComponentCarouselCards: CarouselCards,
    lpComponentImageCard: ImageCard,
    lpComponentFaqDisplay: FAQsDisplay,
  },
  inline: {},
};

export default landingPageEntryLookup;
