import { ILpComponentFaqDisplay } from 'declarations/contentful';
import { EntryComponent } from 'cms/entries/entries';
import { Box, Flex, Text, Link, Divider, Icon } from '@opendoor/bricks/core';
import Grid, { gridStyles } from './Grid';
import { colors } from '@opendoor/bricks/theme/eero'; // TODO: change to novo
import styled from '@emotion/styled';

const Title = styled(Text)`
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const RenderFAQsDisplay = (entry: ILpComponentFaqDisplay) => {
  const { fields } = entry;
  const viewAllLink = (
    <Link
      aria-label="hp-view-all-faqs"
      analyticsName="hp=view-all-faqs"
      href={fields?.viewMoreLinkUrl}
      fontWeight="semibold"
      alignSelf="center"
    >
      View all FAQ{' '}
      {<Icon name="arrow-right" size={16} style={{ color: '#005CE1' }} alignSelf="center" />}
    </Link>
  );
  return (
    <Grid
      style={{
        backgroundColor: '#F6F5F4',
      }}
      pb="112px"
    >
      <Flex
        gridColumn={gridStyles.colSpan12}
        width="100%"
        flexDirection={['column', null, 'row']}
        alignContent="center"
      >
        <Flex width="100%" flexDirection="column">
          <Flex width="100%" justifyContent="space-between" mb="56px" alignItems="flex-end">
            <Text
              as="h2"
              fontWeight="medium"
              size="230"
              mt={[null, null, 0]}
              maxWidth={[null, null, '488px']}
            >
              {fields?.title}
            </Text>
            <Box display={['none', null, 'block']}>{viewAllLink}</Box>
          </Flex>
          <Box>
            <Box>
              {fields?.faQs?.map((faq: any, idx) => {
                return (
                  <Box key={idx}>
                    <Box my={[9, null, 6, 10]} display={idx === 0 ? 'none' : 'block'}>
                      <Divider borderTop={`1px solid ${colors.neutrals40}`} />
                    </Box>
                    <Flex
                      gap={[0, null, '15%']}
                      pr={[null, null, null, null, '91px']}
                      width="100%"
                      flexDir={['column', null, 'row']}
                    >
                      <Box
                        // minWidth={[null, null, '343px']}
                        width={['100%', null, '35%']}
                        mb="6"
                      >
                        <Title fontWeight="medium" size="150">
                          {faq?.fields?.title}
                        </Title>
                      </Box>
                      <Box
                        // minWidth={[null, null, '646px']}
                        width={['100%', null, '55%']}
                      >
                        <Text size="110">{faq?.fields?.body?.content[0].content[0].value}</Text>
                      </Box>
                    </Flex>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box mt="7" display={['block', null, 'none']}>
            {viewAllLink}
          </Box>
        </Flex>
      </Flex>
    </Grid>
  );
};

const FAQsDisplay: EntryComponent<ILpComponentFaqDisplay> = {
  render: RenderFAQsDisplay,
};

export default FAQsDisplay;
