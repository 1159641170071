/* storybook-check-ignore */
import { css } from '@emotion/react';

import { Box, Text } from '@opendoor/bricks/core';

import AddressSearch from '../shared/AddressSearch';
import { ILpComponentHero } from 'declarations/contentful';
import { Awaited, EntryComponent, LoaderFn } from 'cms/entries/entries';
import { PreloadImages, proxyContentfulImage } from '../../helpers/landingPages';
import { useStickyCTABarWhenRefNotVisible } from '../../helpers/StickyCTABarContext';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { globalObservability } from '@opendoor/observability/slim';
import { getAthenaClient } from '../../components/api';
import { searchParamsFromUrl } from '../../helpers/queryParams';
import SelectiveSpritesheet from '@opendoor/bricks/core/Icon/SelectiveSpritesheet';
import ChevronRight from '@opendoor/bricks/core/Icon/SpritesheetIcons/ChevronRight12';

type IHeroLoaderReturn = {
  desktopImageUrl: string;
  mobileImageUrl: string;
  preloadImages: PreloadImages;
  prefilledAddress: string | null;
};

const heroLoader: LoaderFn<ILpComponentHero, IHeroLoaderReturn> = async (
  input,
  _root,
  pageProps,
) => {
  let desktopImageUrl = '';
  let mobileImageUrl = '';
  if (input.fields.desktopImage && input.fields.mobileImage) {
    desktopImageUrl = proxyContentfulImage(input.fields.desktopImage);
    mobileImageUrl = proxyContentfulImage(input.fields.mobileImage);
  }

  const loaderReturn: IHeroLoaderReturn = {
    desktopImageUrl,
    mobileImageUrl,
    preloadImages: [
      { url: desktopImageUrl, media: '(min-width: 768px)' },
      { url: mobileImageUrl, media: '(max-width: 767px)' },
    ],
    prefilledAddress: null,
  };

  try {
    const searchParams = searchParamsFromUrl(pageProps?.url);
    const addressId = searchParams.get('address_id');
    if (addressId) {
      const response = await getAthenaClient().GetAddressForId({
        addressId,
      });
      const data = response.casa.find?.address;
      // 125 West Muriel Drive, Phoenix, AZ, USA
      if (data) {
        const streetWithUnit = `${data.street}${data.unitNumber ? `  #${data.unitNumber}` : ''}`;
        loaderReturn.prefilledAddress = `${streetWithUnit}, ${data.city}, ${data.state} ${data.postalCode}`;
      }
    }
  } catch (e) {
    globalObservability.getSentryClient().captureException?.(e);
  }
  return loaderReturn;
};

const HeroComponent = (
  entry: ILpComponentHero,
  resolvedData?: Awaited<ReturnType<typeof heroLoader>>,
) => {
  const { fields } = entry;
  const actionText = documentToReactComponents(fields.actionText);

  const h1Text = documentToReactComponents(fields.h1);
  const h2Text = documentToReactComponents(fields.h2);

  const desktopHeroImage = resolvedData?.desktopImageUrl;
  const mobileHeroImage = resolvedData?.mobileImageUrl;

  const { ref } = useStickyCTABarWhenRefNotVisible();

  return (
    <>
      <SelectiveSpritesheet icons={[ChevronRight]} />
      <Box
        as="section"
        id="hero-container"
        className="hero-container"
        backgroundImage={[`url(${mobileHeroImage})`, null, `url(${desktopHeroImage})`]}
        backgroundPosition="bottom center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
      >
        <Box
          ref={ref}
          width={['90%', null, '70%', null]}
          m={['0px 5% 270px 5%', '70px 5% 300px 5%', '70px 15% 40px 15%', '185px 0 150px 10%']}
          display="inline-block"
          textAlign="left"
          css={css`
            @media (max-height: 568px) and (max-width: 766px) {
              margin: 10px 5% 130px 5% !important;
            }

            @media (max-height: 568px) and (min-width: 1200px) {
              margin: 30px 5% 20px 5% !important;
            }
          `}
        >
          <Text
            as="h1"
            lineHeight="120"
            maxWidth="520px"
            color="neutrals0"
            mb={[5, null, 6]}
            mt={[7, null, 0]}
            textAlign={['center', null, 'left']}
            fontSize={['s4', null, 's5', 's5']}
            fontWeight="semibold"
            position="relative"
          >
            {h1Text}
          </Text>
          <Box maxWidth="520px" m={['0 auto', null, '0 0']}>
            <AddressSearch
              inputLocation="hero-cta"
              trackingTaxonomy="new_homepage"
              actionText={actionText}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={true}
              prefilledAddress={resolvedData?.prefilledAddress ?? undefined}
            />
          </Box>
          <Text
            color="neutrals0"
            lineHeight="140"
            fontWeight="bold"
            fontSize={['s0', 's0', 's2']}
            textAlign={['center', null, 'left']}
            position="relative"
            className="sub-head"
            py={[3, 3, 3, 7]}
            mt={[null, null, 2]}
            mb={[3, 3, null]}
          >
            {h2Text}
          </Text>
        </Box>
      </Box>
    </>
  );
};

const Hero: EntryComponent<ILpComponentHero, Awaited<ReturnType<typeof heroLoader>>> = {
  render: HeroComponent,
  loader: heroLoader,
};

export default Hero;
