/* storybook-check-ignore */
import { Box, Flex, FlexProps } from '@opendoor/bricks/core';
import { ColorScheme } from '@opendoor/bricks/system/theme';
import { Token } from '@opendoor/bricks/system/token.types';

export const gridStyles = {
  margin: [6, 6, 9, 11, 11],
  colSpan12: 'span 12 / span 12',
};

interface GridProps extends FlexProps {
  backgroundColor?: Token<ColorScheme>;
  horizontalMargin?: boolean;
  verticalMargin?: boolean;
  children: React.ReactNode;
}

const Grid: React.FC<GridProps> = ({
  backgroundColor,
  children,
  verticalMargin = false,
  horizontalMargin = true,
  maxWidth,
  ...props
}) => {
  return (
    <Flex width="100%" justifyContent="center" {...props} backgroundColor={backgroundColor}>
      <Flex width="100%" maxWidth={maxWidth || '1440px'}>
        <Box
          gridTemplateColumns="repeat(12, minmax(0, 1fr))"
          display="grid"
          columnGap={[0, 5, 6]}
          mx={horizontalMargin ? gridStyles.margin : undefined}
          my={verticalMargin ? gridStyles.margin : undefined}
          width="100%"
        >
          {children}
        </Box>
      </Flex>
    </Flex>
  );
};

export default Grid;
