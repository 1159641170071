import { useEffect, useState } from 'react';

import { useAuth } from '@opendoor/auth-fe';

import { getAthenaClient } from '../components/api';
import { CONSUMER_FE_URL } from '../components/globals';
import { OdProtosWebData_Offer_State } from '__generated__/athena';

export type Lead = {
  id: string;
  url: string;
  address: {
    street?: string;
  };
  readable_offer_state?: string;
};

const buildHubUrl = (addressUuid?: string) => {
  return `${CONSUMER_FE_URL}/dashboard/sell/${addressUuid}`;
};

const getReadableOfferState = (offerState?: OdProtosWebData_Offer_State) => {
  if (offerState === 'SENT' || offerState === 'ACCEPTED') {
    return 'Offered';
  } else if (offerState === 'EXPIRED') {
    return 'Expired';
  } else if (offerState === 'CONTINGENCY_RELEASED' || offerState === 'IN_CONTRACT') {
    return 'In Contract';
  } else if (offerState === 'CLOSED') {
    return 'Closed';
  }

  return;
};

const fetchLeads = async (customerUuid?: string | null | undefined): Promise<Array<Lead>> => {
  let leadsToShow: Lead[] = [];

  if (customerUuid) {
    const leadsResponse = await getAthenaClient().ListLeadsOnUniqueAddresses({
      customerUuid: customerUuid,
    });

    if (leadsResponse.sellDirect?.listLeadsOnUniqueAddresses?.leads) {
      const leads = leadsResponse.sellDirect?.listLeadsOnUniqueAddresses?.leads;

      leadsToShow = leads
        .filter((lead) => lead.isQualified)
        .map((lead) => {
          return {
            id: lead.id,
            url: buildHubUrl(lead.address?.uuid),
            address: {
              street: lead.address?.street,
            },
            readable_offer_state: getReadableOfferState(lead.latestOffer?.state),
          };
        });
    }
  }
  return leadsToShow;
};

export const useLeads = () => {
  const { user, authentication } = useAuth();

  const [leads, setLeads] = useState<Array<Lead>>([]);

  const customerUuid = user?.customerUuid;
  useEffect(() => {
    // wait for auth to finish loading before getting leads
    if (
      !(authentication.state === 'authenticated' || authentication.state === 'not_authenticated')
    ) {
      return;
    }

    async function start() {
      const leadsToShow = await fetchLeads(customerUuid);
      if (!leadsToShow) {
        return;
      }
      setLeads(leadsToShow);
    }

    start();
  }, [authentication.state, customerUuid]);

  return leads;
};
