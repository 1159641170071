/* storybook-check-ignore */
import Image, { ImageLoader } from 'next/image';
import { legacy } from '@opendoor/bricks/theme';

type ContentfulAsset = {
  description?: string;
  file: {
    url: string;
    details: {
      image: {
        width: number;
        height: number;
      };
    };
  };
};

export const isAsset = (x: any): x is ContentfulAsset => {
  return x.file && x.file?.details?.image;
};

const contentfulLoader: ImageLoader = (props) => {
  const { src, width, quality } = props;
  return `${src}?w=${width}&q=${quality || 75}`;
};

type ImageProps = {
  src: string;
  description?: string;
  width: number;
  height: number;
};
const renderImage = (props: ImageProps) => {
  return (
    <Image
      loader={contentfulLoader}
      layout="responsive"
      src={props.src}
      sizes={`(max-width: ${legacy.breakpoints[3]}) 100vw, 50vw`}
      alt={props.description || ''}
      width={props.width}
      height={props.height}
    />
  );
};

export default renderImage;
