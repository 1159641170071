import { ILpComponentImageCard } from 'declarations/contentful';
import { Text, Flex, Image, Button } from '@opendoor/bricks/core';
import { EntryComponent } from 'cms/entries/entries';
import Grid, { gridStyles } from './Grid';
import { useDevice } from '@opendoor/bricks/hooks/useMedia';
import { novo } from '@opendoor/bricks/theme';
import LazyLoad from 'react-lazyload';

const RenderImageCard = (entry: ILpComponentImageCard) => {
  const { fields } = entry;
  const desktopImageSourceUrl = fields?.desktopImage;
  const mobileImageSourceUrl = fields?.mobileImage;

  const { isSmallMobile, isMobile } = useDevice();

  return (
    <Grid
      // @ts-expect-error TODO - @growth update after brown95 is added to Novo theme
      backgroundColor="#F6F5F4"
    >
      <Flex
        gridColumn={gridStyles.colSpan12}
        flexDirection={['column', null, 'row', null, null]}
        minWidth={['280px', '335px', '668px', '1073px', '1312px']}
        height={['495px', '624px', '460px', '712px']}
        my={[12, null, null, '160px']}
        borderRadius="roundedSmooth"
        overflow="hidden"
        backgroundColor="neutrals0"
      >
        <Flex
          // flex=1 needs to be in a wrapper Flex because padding
          // throws off the width distribution
          flex={[0.6, 1, 1, null, null]}
        >
          <Flex padding={[8, null, 9, 11, null]} flexDirection="column" justifyContent="start">
            <Flex flexGrow={1}>
              <Text size="180" fontWeight="medium">
                {fields.subtitle}
              </Text>
            </Flex>
            <Flex flexDirection="column">
              <Text
                width={['197px', '249px', '269px', '417px']}
                height={['75px', '93px', '105px', '168px']}
              >
                <Text as="span" size="220" fontWeight="medium">
                  {fields.title}
                </Text>
                <Text as="span" size="220" fontWeight="medium" color="neutrals70">
                  {' ' + fields.secondaryTitle}
                </Text>
              </Text>
              {!isSmallMobile && !isMobile && (
                <Text
                  as="span"
                  size="100"
                  fontWeight="regular"
                  mt={[null, null, 6, 8, null]}
                  width={[null, null, '230px', '384px']}
                >
                  {fields.description}
                </Text>
              )}
              <Button
                variant="primary"
                aria-label="cosmos-hp-browse-homes-link"
                analyticsName="cosmos-hp-browse-homes-link"
                onClick={() => window.location.assign('/homes')}
                mt={[7, null, 9, 10, null]}
                width={['145px']}
                minHeight={['48px']}
                style={{
                  borderRadius: novo.radii.semiRounded,
                  padding: '10.5px 12px',
                }}
              >
                {fields?.ctaDisplayText}
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          height={['45%', '50%', '100%', null, null]}
          flex={[0.4, 1, 1, null, null]}
          padding={[null, null, 5, 6]}
        >
          <LazyLoad offset={300}>
            <Image
              display={['block', null, 'none']}
              src={mobileImageSourceUrl}
              alt="illustration of a home"
              borderRadius={[null, null, 'semiRounded']}
              objectFit="cover"
              objectPosition="80%"
              width="100%"
              loading="lazy"
            />
            <Image
              display={['none', null, 'block']}
              src={desktopImageSourceUrl}
              alt="illustration of a home"
              borderRadius={[null, null, 'semiRounded']}
              objectFit="cover"
              objectPosition="80%"
              width="100%"
              loading="lazy"
            />
          </LazyLoad>
        </Flex>
      </Flex>
    </Grid>
  );
};

const ImageCard: EntryComponent<ILpComponentImageCard> = {
  render: RenderImageCard,
};

export default ImageCard;
