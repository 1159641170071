import { Box, Flex, Text, Image, Heading, H3 } from '@opendoor/bricks/core';
import { shadows } from '@opendoor/bricks/theme/eero';
import imgixUrl from '../../helpers/imgixUrl';
import { ILpComponentSellingOptions } from 'declarations/contentful';
import { EntryComponent } from 'cms/entries/entries';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { ReactNode } from 'react';
import typography from '../../components/landing-pages/typography';

const LineItem = (props: { header: string; body: ReactNode; reduceBottomMargin?: boolean }) => (
  <Flex
    height={['120px', null, '130px', '130px']}
    mb={props.reduceBottomMargin ? [-6, null, 'initial', 'initial'] : undefined}
  >
    <Box position={'relative'} bottom={'3px'}>
      <Image
        width="14px"
        src={imgixUrl('bf56f1ba64e54792bdc4af8385ae7c5b.png')}
        alt="Checkmark icon"
      />
    </Box>
    <Box pl="4" width={0.95}>
      <Text fontSize="s0" lineHeight="s00" fontWeight="semibold">
        {props.header}
      </Text>
      <Text mt={4} fontSize={['s00', null, 's0']}>
        {props.body}
      </Text>
    </Box>
  </Flex>
);

const RenderSellingOptions = (entry: ILpComponentSellingOptions) => {
  const { fields } = entry;
  const header = fields.header;
  const h1 = fields.h1;
  const body = fields.body;
  const lineItems = fields.lineItems;
  let lineItemsParsed;

  if (lineItems) {
    lineItemsParsed = lineItems.map((lineItem) => {
      return {
        header: lineItem.fields.title,
        body: documentToReactComponents(lineItem.fields.body as Document),
      };
    });
  }

  return (
    <Box
      id="selling-options-container"
      as="section"
      mt={[9, null, 11, 11]}
      mb={12}
      textAlign="center"
    >
      <Heading
        size="UNSAFE_h4"
        as="h4"
        mx="auto"
        {...typography.h4}
        my={[null, null, 8, 8]}
        width={[0.7, null, 'initial']}
      >
        {header}
      </Heading>
      <Flex
        margin="auto"
        maxWidth={[null, null, null, '960px']}
        flexDirection={['column', null, 'initial', 'initial']}
        justifyContent="space-between"
      >
        <Box
          mb={[7, null, '-24px', '-24px']}
          px={[5, null, 9]}
          pt={[8, null, 9]}
          pb={[6, null, 7]}
          mx="auto"
          width={['90%', null, '90%', '460px']}
          borderTop="10px solid"
          borderTopColor="brand50"
          borderRadius="roundedSquare"
          backgroundColor="neutrals0"
          textAlign="left"
          css={{ boxShadow: shadows.large }}
        >
          <H3 my={0}>{h1}</H3>
          <Text
            py={[4, null, 7, 7]}
            fontSize={['s00', null, 's0']}
            borderBottom="1px solid"
            borderBottomColor="neutrals40"
          >
            {body}
          </Text>
          <Box pt={[7, null, 4, 4]}>
            {lineItemsParsed &&
              lineItemsParsed.map((lineItem, index) => (
                <LineItem key={index} header={lineItem.header} body={lineItem.body} />
              ))}
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

const SellingOptions: EntryComponent<ILpComponentSellingOptions> = {
  render: RenderSellingOptions,
};

export default SellingOptions;
