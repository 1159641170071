/* storybook-check-ignore */
import { useState } from 'react';
import { Box, Button, Flex, Heading, Text } from '@opendoor/bricks/core';
import { FullScreenModal } from '@opendoor/bricks/complex/Modal';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import NextImage from 'next/image';
import SelectiveSpritesheet from '@opendoor/bricks/core/Icon/SelectiveSpritesheet';
import CloseIcon from '@opendoor/bricks/core/Icon/SpritesheetIcons/Close';

import { EntryComponent } from '../../cms/entries/entries';
import { imgixLoader } from '../../helpers/imgixUrl';
import AddressSearch from '../../components/shared/AddressSearch';
import { ILpComponentVideoHero } from '../../declarations/contentful';
import Grid, { gridStyles } from './Grid';

import { useInView } from 'react-intersection-observer';
import { StickyCTAUtility } from './StickyCTAUtility/StickyCTAUtility';
import { useDevice } from '@opendoor/bricks/hooks/useMedia';

import Vimeo from '@u-wave/react-vimeo';
import {
  generateDeprecatedImgixPictureSet,
  generateDeprecatedPictureSizeSet,
} from '../shared/performance/pictures';

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 540px 540px 72px 72px;
  aspect-ratio: 4 / 5;
`;

const RenderVideoHeroCard = (entry: ILpComponentVideoHero) => {
  // empty commit to restart CI checks

  const { fields } = entry;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openVideoModalHandler = () => {
    setIsModalOpen(true);
  };

  const {
    ref: addressEntryRef,
    inView: addressEntryInView,
    entry: intersectionObserverEntry,
  } = useInView();
  // for the first intersection observer event, the entry is undefined
  // as the element loads. We want to make sure not to flash the sticky
  // cta while it loads for the first time.
  const showStickyCTAUtility =
    Boolean(intersectionObserverEntry) && !addressEntryInView && !isModalOpen;

  const { isSmallMobile, isMobile, isTablet, isDesktop } = useDevice();
  const notMobile = !isSmallMobile && !isMobile;

  const videoPlayerWidth = () => {
    if (isSmallMobile) {
      return '290px';
    } else if (isMobile) {
      return '310px';
    } else if (isTablet) {
      return '740px';
    } else if (isDesktop) {
      return '1100px';
    }
    return '1300px';
  };

  const videoPlayerHeight = () => {
    if (isSmallMobile) {
      return '150px';
    } else if (isMobile) {
      return '200px';
    } else if (isTablet) {
      return '416px';
    }
    return '600px';
  };

  return (
    <Box>
      <SelectiveSpritesheet icons={[CloseIcon]} />
      <StickyCTAUtility isVisible={showStickyCTAUtility} />
      <FullScreenModal
        onRequestClose={() => setIsModalOpen(false)}
        analyticsName="video-hero-modal"
        isOpen={isModalOpen}
        headerIconButtonVariant="icon"
        title="How it works"
        theme="novo"
      >
        <Flex
          flexDirection="column"
          height={['600px', null, '100%']}
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          {fields?.showAddressInputCta && (
            <Box
              width={['100%', null, '100%', '500px']}
              paddingTop={6}
              paddingBottom={[9, null, 11]}
              maxWidth={[null, '420px', null]}
              minWidth={[null, null, '335px']}
              className="hp-reskin-address-entry-wrapper"
            >
              <AddressSearch
                hideLabel
                inputLocation="hero-cta"
                trackingTaxonomy="new_homepage"
                actionText={fields?.addressCtaText || 'Get cash offer'}
                actionTextSmall={fields?.addressCtaTextSmall || 'Get offer'}
                placeholderText={fields?.addressCtaPlaceholderText}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                showShadow={false}
              />
            </Box>
          )}
          <Vimeo
            keyboard
            width={videoPlayerWidth()}
            height={videoPlayerHeight()}
            video="https://vimeo.com/726130027" // "how it works" vimeo video
          />
        </Flex>
      </FullScreenModal>
      <Grid>
        <Box gridColumn={gridStyles.colSpan12} paddingTop={[5, null, 6]}>
          <Flex
            zIndex="1"
            flexDirection={['column', null, 'row']}
            justifyContent="space-between"
            width="100%"
            columnGap={[null, null, 4]}
          >
            <Flex
              id="title-and-address-entry"
              flexDirection="column"
              mt={[5]}
              width={['100%', null, '50%']}
              mb={[10, null, 8]}
            >
              <Flex
                marginTop={[0, null, 8, 12]}
                flexDirection="column"
                marginBottom={[4, null, 11, 9]}
                marginLeft={0}
                width="100%"
                alignItems={[null, 'center', 'unset']}
              >
                <Heading
                  as="h1"
                  color="neutrals100"
                  marginTop={[6, null, 11, 0]}
                  fontWeight="medium"
                  marginBottom={[0, null, 6]}
                  textAlign={['center', null, 'left']}
                  maxWidth={[null, '305px', '480px', '543px']}
                  css={css`
                    line-height: 100%;
                    font-size: 48px;
                    letter-spacing: -0.03em;
                    @media (min-width: 376px) and (max-width: 768px) {
                      font-size: 64px;
                      line-height: 100%;
                      letter-spacing: -0.034em;
                    }
                    @media (min-width: 769px) and (max-width: 1048px) {
                      font-size: 56px;
                      line-height: 104%;
                      letter-spacing: -0.034em;
                    }
                    @media (min-width: 1049px) and (max-width: 1440px) {
                      font-size: 96px;
                      line-height: 95%;
                      letter-spacing: -0.034em;
                    }
                    @media (min-width: 1441px) {
                      line-height: 95%;
                      font-size: 112px;
                      letter-spacing: -0.034em;
                    }
                  `}
                >
                  {fields?.title}
                </Heading>
                <Text
                  as="h2"
                  size="160"
                  color="neutrals100"
                  marginTop={[5, null, 6]}
                  marginBottom={0}
                  textAlign={['center', null, 'left']}
                  maxWidth={[null, '235px', '211px', '313px']}
                >
                  {fields?.subtitle}
                </Text>
              </Flex>
              <Box visibility="hidden" ref={addressEntryRef} />
              <Flex justifyContent={['center', null, 'flex-start']}>
                <Box
                  width={['100%', null, '100%', '500px']}
                  paddingTop={[6, null, 'unset']}
                  maxWidth={[null, '420px', null]}
                  minWidth={[null, null, '335px']}
                  zIndex={10}
                  className="hp-reskin-address-entry-wrapper"
                >
                  <AddressSearch
                    hideLabel
                    inputLocation="hero-cta"
                    trackingTaxonomy="new_homepage"
                    actionText={fields?.addressCtaText || 'Get cash offer'}
                    actionTextSmall={fields?.addressCtaTextSmall || 'Get offer'}
                    placeholderText={fields?.addressCtaPlaceholderText}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    showShadow={false}
                  />
                </Box>
              </Flex>
            </Flex>
            <Flex
              id="inline-video"
              width={['100%', null, '50%']}
              alignItems="center"
              justifyContent={['center', null, 'flex-end']}
              minWidth={[null, null, '350px', null]}
            >
              <Box
                position="relative"
                height={['431px', '543px', '480px', '680px', '680px']}
                width="100%"
                maxWidth="582px"
              >
                <Video
                  preload="auto"
                  autoPlay
                  muted
                  loop
                  playsInline
                  // TODO: I think the image is fetched before this line executes and it picks Desktop version always
                  poster={notMobile ? fields?.videoInitialImageDesktop : fields?.videoInitialImage}
                  src="https://odfil.es/static/videos/videohero-autoplay.mp4"
                />
                <Button
                  position="absolute"
                  aria-label="open-how-it-works-video-modal"
                  analyticsName="cosmos-landing-page-open-how-it-works-video-modal"
                  transform={['translate(-15%, 0px)', 'translate(-50%, -20%)']}
                  left={['30%', '50%']}
                  top="50%"
                  zIndex="7"
                  onClick={openVideoModalHandler}
                  variant="link"
                >
                  <Flex
                    px={5}
                    py={4}
                    alignItems="center"
                    style={{
                      backgroundColor: 'rgba(41, 40, 39, 0.64)',
                      borderRadius: '18px',
                    }}
                    backdropFilter="blur(20px)"
                  >
                    <Box mr={3}>
                      <NextImage
                        layout="fixed"
                        width="14px"
                        height="14px"
                        loader={imgixLoader}
                        src="2023-06-1686866816613-88291.svg"
                        alt="Video play icon"
                      />
                    </Box>
                    <Text fontWeight="semibold" color="neutrals0" size="110">
                      {'How it works'}
                    </Text>
                  </Flex>
                </Button>
              </Box>
            </Flex>
          </Flex>
        </Box>
        <Flex
          id="featured-in"
          gridColumn={gridStyles.colSpan12}
          mt={[9, 7, 5, 'unset']}
          justifyContent="center"
        >
          <Flex
            position={[null, null, null, 'absolute']}
            transform={[null, null, null, 'translate(0, -135%)']}
            width={['100%', null, 'fit-content']}
            justifyContent="center"
            style={{
              borderRadius: '20px',
              backgroundColor: 'rgba(242, 241, 240, 0.64)',
              backdropFilter: 'blur(20px)',
            }}
          >
            <Flex
              maxWidth={['200px', null, 'unset']}
              flexWrap="wrap"
              rowGap={3}
              columnGap={[4, 5, 6]}
              p={[4, 5, 6]}
              alignItems="center"
              justifyContent={['space-between', 'center']}
              css={css`
                @media (min-width: 375px) and (max-width: 420px) {
                  max-width: 240px;
                }
                @media (min-width: 421px) {
                  max-width: unset;
                }
              `}
            >
              <Text
                order={1}
                textAlign={['center', 'unset']}
                width={['100%', 'unset']}
                fontSize={['s00', null, 's1']}
                style={{
                  color: '#8F8E8D',
                }}
                css={css`
                  @media (min-width: 375px) and (max-width: 562px) {
                    width: 100%;
                    text-align: center;
                  }
                `}
              >
                Featured in:
              </Text>
              <Box
                width={['60px', '64px', '75px']}
                height="auto"
                order={[2, null, 3]}
                css={css`
                  @media (min-width: 375px) and (max-width: 562px) {
                    order: 3;
                  }
                `}
              >
                {generateDeprecatedImgixPictureSet({
                  // Forbes logo
                  photoUrl: 'https://imgdrop.imgix.net/2023-06-1686940301858-79318.svg',
                  sizes: generateDeprecatedPictureSizeSet([
                    { w: 60, h: 16 },
                    { w: 60, h: 16 },
                    { w: 64, h: 18 },
                    { w: 75, h: 20 },
                    { w: 75, h: 20 },
                  ]),
                  debugImageSizes: false,
                  radii: {},
                  lazy: true,
                })}
              </Box>
              <Box width={['60px', '70px', '78px']} order={[3, null, 5]}>
                {generateDeprecatedImgixPictureSet({
                  // Fortune logo
                  photoUrl: 'https://imgdrop.imgix.net/2023-06-1686940301853-09939.svg',
                  sizes: generateDeprecatedPictureSizeSet([
                    { w: 60, h: 16 },
                    { w: 60, h: 16 },
                    { w: 64, h: 18 },
                    { w: 75, h: 20 },
                    { w: 75, h: 20 },
                  ]),
                  debugImageSizes: false,
                  radii: {},
                  lazy: true,
                })}
              </Box>
              <Box width={['125px', '132px', '155px']} order={[4, null, 2]}>
                {generateDeprecatedImgixPictureSet({
                  // New York Times logo
                  photoUrl: 'https://imgdrop.imgix.net/2023-06-1686940301848-90433.svg',
                  sizes: generateDeprecatedPictureSizeSet([
                    { w: 125, h: 16 },
                    { w: 126, h: 16 },
                    { w: 140, h: 18 },
                    { w: 145, h: 20 },
                    { w: 145, h: 20 },
                  ]),
                  debugImageSizes: false,
                  radii: {},
                  lazy: true,
                })}
              </Box>
              <Box width={['30px', '35px', '38px']} order={[5, null, 4]}>
                {generateDeprecatedImgixPictureSet({
                  // Wall Street Journal logo
                  photoUrl: 'https://imgdrop.imgix.net/2023-06-1686940301856-48698.svg',
                  sizes: generateDeprecatedPictureSizeSet([
                    { w: 125, h: 16 },
                    { w: 126, h: 16 },
                    { w: 140, h: 18 },
                    { w: 145, h: 20 },
                    { w: 145, h: 20 },
                  ]),
                  debugImageSizes: false,
                  radii: {},
                  lazy: true,
                })}
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Grid>
    </Box>
  );
};

const VideoHero: EntryComponent<ILpComponentVideoHero> = {
  render: RenderVideoHeroCard,
};

export default VideoHero;
