/* storybook-check-ignore */
import React from 'react';
import { Text, Flex, Card, Link, Box } from '@opendoor/bricks/core';
import { novo } from '@opendoor/bricks/theme';

import {
  generateDeprecatedImgixPictureSet,
  generateDeprecatedPictureSizeSet,
} from '../../shared/performance/pictures';
import LazyLoad from 'react-lazyload';

const SPACER = 6;
const BROWN95 = '#F6F5F4';

const IMAGE_SIZE_CONFIG = generateDeprecatedPictureSizeSet([
  { w: 80, h: 80 },
  { w: 80, h: 80 },
  { w: 112, h: 112 },
  { w: 112, h: 112 },
  { w: 112, h: 112 },
]);

export const ReviewCard: React.FC<{
  image: string;
  quote: string;
  analyticsName: string;
  link?: string;
  name?: string;
  market?: string;
}> = ({ image, quote, analyticsName, link, name, market }) => {
  return (
    <Card
      variant="primary"
      minHeight={['460', null, '500', null, null]}
      maxHeight={[null, null, '500', null, null]}
      minWidth={['264', '319', '378', null, null]}
      maxWidth={[null, null, '378', null, null]}
      style={{
        backgroundColor: BROWN95,
        borderRadius: '36px',
        boxShadow: novo.shadows.none,
      }}
    >
      <Flex
        flexDirection="column"
        textAlign="center"
        alignItems="center"
        gap={SPACER}
        flexGrow={1}
        mt={SPACER}
      >
        <Box
          width={['80px', null, '112px']}
          height={['80px', null, '112px']}
          borderRadius="rounded"
          overflow="hidden"
        >
          <LazyLoad offset={300}>
            {generateDeprecatedImgixPictureSet({
              photoUrl: image,
              sizes: IMAGE_SIZE_CONFIG,
              debugImageSizes: false,
              radii: {},
              lazy: true,
            })}
          </LazyLoad>
        </Box>
        <Text
          size="110"
          fontWeight="regular"
          style={{
            lineHeight: '25.6px',
          }}
          minWidth={[null, null, null, '250px']}
        >
          {quote}
        </Text>
        <Link href={link} aria-label={analyticsName} analyticsName={analyticsName}>
          <Text fontWeight="medium" size="110" color="brand50">
            Read more
          </Text>
        </Link>
      </Flex>
      <Flex flexDirection="column" textAlign="center" marginBottom={SPACER}>
        <Text size="120" fontWeight="medium" margin={0}>
          {name}
        </Text>
        <Text size="100" fontWeight="medium" color="neutrals70" margin={0}>
          {market}
        </Text>
      </Flex>
    </Card>
  );
};
