/** storybook-check-ignore */
import React from 'react';
import { colors } from '@opendoor/bricks/theme/eero';

export const EiChevronDownIcon: React.FC = () => {
  const ChevronDownSVG = () => (
    <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="1394.6 97.8 430.2 243.7">
      <path
        fill={colors.neutrals100}
        d="M1824.9 129.7c0 8.4-2.8 15.4-8.4 21L1634.2 333c-11.2 11.2-30.9 11.2-42.1 0l-187.9-182.3c-12.6-11.2-12.6-30.9 0-43.5 11.2-12.6 30.9-12.6 43.5 0l164 162.8L1773 108.7c11.2-11.2 30.9-11.2 43.5 0 5.5 5.6 8.4 14 8.4 21z"
      />
    </svg>
  );
  return <ChevronDownSVG />;
};
