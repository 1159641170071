import { ILpComponentTextSection } from 'declarations/contentful';
import { Box, Flex, Text } from '@opendoor/bricks/core';
import { EntryComponent } from 'cms/entries/entries';
import Grid, { gridStyles } from './Grid';
import { css } from '@emotion/react';

const renderTextSection = (entry: ILpComponentTextSection) => {
  const { fields } = entry;

  return (
    <Grid>
      <Flex
        gridColumn={gridStyles.colSpan12}
        width="100%"
        flexDirection={['column', null, 'row']}
        mt={[11, '96px', 12, '136px']}
        mb={[10, 11, 11, '96px']}
        justifyContent={[null, null, 'space-between']}
        alignItems={[null, null, 'center']}
        gap={[6, null, 8]}
      >
        <Flex width={['100%', null, 'unset']} flexDir="column">
          <Box>
            <Text fontWeight="medium" my={0} as="h2" size="230" color="neutrals100">
              {fields?.title}
            </Text>
          </Box>
          <Box>
            <Text as="h2" fontWeight="medium" size="230" my={0} color="brand50">
              {fields?.subtitle}
            </Text>
          </Box>
        </Flex>
        <Box
          maxWidth={['235px', '235px', '330px', '424px']}
          css={css`
            @media (min-width: 768px) and (max-width: 910px) {
              max-width: 235px;
            }
          `}
        >
          <Text size="150">{fields?.description}</Text>
        </Box>
      </Flex>
    </Grid>
  );
};

const TextSection: EntryComponent<ILpComponentTextSection> = {
  render: renderTextSection,
};

export default TextSection;
