import { Box, Flex, Text, Heading, Image } from '@opendoor/bricks/core';
import { ILpComponentSteps } from 'declarations/contentful';
import { Awaited, EntryComponent } from 'cms/entries/entries';
import { Asset, Entry } from 'contentful';
import { proxyContentfulImage } from '../../helpers/landingPages';
import typography from '../../components/landing-pages/typography';

interface StepperCardProps {
  imageUrl: string;
  stepNumber: string;
  header: string;
  description: string;
  alt: string;
}

export type IStepsLoaderReturn = {
  imageData: { stepNumber: number; url: string }[];
};

const stepsLoader = async (input: ILpComponentSteps, _root?: Entry<any>) => {
  const stepperCards = input.fields.stepperCards;
  const imageData = [] as { stepNumber: number; url: string }[];
  if (stepperCards) {
    stepperCards.forEach((card: { fields: { image: Asset; stepNumber: any } }) => {
      const url = proxyContentfulImage(card.fields.image);
      const stepNumber = card.fields.stepNumber;
      imageData.push({ stepNumber, url });
    });
  }

  const loaderReturn: IStepsLoaderReturn = {
    imageData,
  };

  return loaderReturn;
};

const StepperCard = (props: StepperCardProps) => (
  <Box maxWidth={['340px', null, '300px', '300px']} mx={4} mt={8} role="group" aria-label="step">
    <Box alignSelf={['center', null, 'flex-end', 'flex-end']}>
      <Image src={props.imageUrl} alt={props.alt || ''} />
    </Box>
    <Flex
      flexDirection={['row', null, 'column', 'column']}
      mt={[5, null, 7, 7]}
      pr={[null, null, 8, 8]}
    >
      <Text color="brand50" fontWeight="bold" fontSize="s00" width={['40%', null, '100%', '100%']}>
        STEP {props.stepNumber}
      </Text>
      <Box>
        <Heading
          size="UNSAFE_h4"
          as="h4"
          fontSize={['s1', null, 's2', 's2']}
          mt={0}
          mb={[4, null, 5, 5]}
          lineHeight={['s0', null, 's2', 's2']}
        >
          {props.header}
        </Heading>
        <Text fontSize={['s0', null, 's1', 's1']} lineHeight="bodyTighter">
          {props.description}
        </Text>
      </Box>
    </Flex>
  </Box>
);

const renderSteps = (
  entry: ILpComponentSteps,
  resolvedData?: Awaited<ReturnType<typeof stepsLoader>>,
) => {
  const { fields } = entry;
  const h1 = fields.h1;
  const h2 = fields.h2;
  const body = fields.body;
  const imageData = resolvedData?.imageData;
  const stepperCards = fields.stepperCards;

  return (
    <Box
      mx="auto"
      mt={[9, null, 8, 8]}
      maxWidth={['95%', null, '960px', '960px']}
      id="how-it-works"
    >
      <Box textAlign="center">
        <Box>
          <Heading size="UNSAFE_h4" as="h4" lineHeight="bodyTighter" {...typography.h4}>
            {h1}
          </Heading>
          <Heading size="UNSAFE_h4" as="h4" color="brand50" {...typography.h4}>
            {h2}
          </Heading>
        </Box>
        <Text
          width={['95%', '450px', '500px']}
          mx="auto"
          lineHeight="150"
          fontSize={['s0', null, 's1', 's1']}
        >
          {body}
        </Text>
      </Box>
      <Flex
        flexDirection={['column', null, 'row', 'row']}
        alignItems={['center', null, 'flex-start', 'flex-start']}
        justifyContent="space-between"
        mt={[null, null, 7, 7]}
      >
        {stepperCards &&
          stepperCards.map((card) => {
            const image = imageData?.find((image) => image.stepNumber === card.fields.stepNumber);
            if (!image) {
              return null;
            }
            return (
              <StepperCard
                key={card.fields.header}
                imageUrl={image.url}
                stepNumber={String(card.fields.stepNumber)}
                header={card.fields.header}
                description={card.fields.description}
                alt={card.fields.altText}
              />
            );
          })}
      </Flex>
    </Box>
  );
};

const Steps: EntryComponent<ILpComponentSteps, Awaited<ReturnType<typeof stepsLoader>>> = {
  render: renderSteps,
  loader: stepsLoader,
};

export default Steps;
