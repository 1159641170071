import { useInView } from 'react-intersection-observer';
import Slider from 'react-slick';

import { Box, Heading, Text } from '@opendoor/bricks/core';
import GlobalSlickStyles from '../shared/GlobalSlickStyles';
import { useObservability } from '../../helpers/observability';

import { ILpComponentQuoteItem, ILpComponentTestimonials } from 'declarations/contentful';
import { Awaited, EntryComponent } from 'cms/entries/entries';
import { Entry } from 'contentful';
import { proxyContentfulImage } from '../../helpers/landingPages';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import typography from '../../components/landing-pages/typography';

interface QuoteItemProps {
  href: string;
  quoteAuthor: string;
  locationText: string;
  children: any;
}

export type ITestimonialsLoaderReturn = {
  desktopImageUrl: string;
  mobileImageUrl: string;
};

const testimonialsLoader = async (input: ILpComponentTestimonials, _root?: Entry<any>) => {
  const desktopImageUrl = proxyContentfulImage(input.fields.desktopImage);
  const mobileImageUrl = proxyContentfulImage(input.fields.mobileImage);

  const loaderReturn: ITestimonialsLoaderReturn = {
    desktopImageUrl,
    mobileImageUrl,
  };

  return loaderReturn;
};

const QuoteItem = (props: QuoteItemProps) => {
  const { trackEvent } = useObservability();
  return (
    <Box mx="auto" width={['90%', null, 'initial', 'initial']}>
      <Text mb={['initial', null, 7, 7]} textAlign="center" lineHeight="150" fontSize="s1">
        {props.children}
      </Text>
      <Box mt={[7, null, 'initial', 'initial']}>
        <a
          // TODO: remove categoryOverride
          // observability is initialized with 'cosmos' as the category for all events
          onClick={() =>
            trackEvent('cta-click', 'customer-story-link', undefined, {
              categoryOverride: 'homepage',
            })
          }
          href={props.href}
          target="_blank"
          rel="noreferrer"
        >
          {props.quoteAuthor}
        </a>
        <br />
        {props.locationText}
      </Box>
    </Box>
  );
};

const GenerateQuoteItems = (quoteItems: ILpComponentQuoteItem[] | undefined) => {
  if (!quoteItems) {
    return null;
  }
  return quoteItems.map((quoteItem, index) => {
    return (
      <QuoteItem
        key={quoteItem.fields.author}
        href={quoteItem.fields.storyLink || ''}
        quoteAuthor={quoteItem.fields.author || ''}
        locationText={quoteItem.fields.locationText || ''}
        aria-roledescription="slide"
        aria-label={`${index + 1} of ${quoteItems.length}`}
      >
        {quoteItem.fields.message}
      </QuoteItem>
    );
  });
};

const RenderTestimonials = (
  entry: ILpComponentTestimonials,
  resolvedData?: Awaited<ReturnType<typeof testimonialsLoader>>,
) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { fields } = entry;
  const header = documentToReactComponents(fields.header);
  const quoteItems = GenerateQuoteItems(fields.quoteItems);
  const desktopImage = resolvedData?.desktopImageUrl;
  const mobileImage = resolvedData?.mobileImageUrl;

  return (
    <Box id="testimonials-container" ref={ref}>
      <GlobalSlickStyles />
      {inView && (
        <Box
          as="section"
          backgroundPosition="center"
          backgroundSize="cover"
          height={['480px', null, '770px', '770px']}
          backgroundImage={[`url(${mobileImage})`, null, `url(${desktopImage})`]}
        >
          <Heading
            size="UNSAFE_h4"
            as="h4"
            mt={0}
            textAlign={['center', null, 'center', 'center']}
            pt={[9, null, 11, 11]}
            {...typography.h4}
          >
            {header}
          </Heading>
          <section aria-roledescription="carousel" aria-label="Highlighted Opendoor reviews">
            <Box
              width={['90%', null, '70%', '70%']}
              textAlign="center"
              mx="auto"
              mt={[7, null, 8, 8]}
            >
              <Slider dots={false} arrows={true} autoplay={false} accessibility={true}>
                {quoteItems}
              </Slider>
            </Box>
          </section>
        </Box>
      )}
    </Box>
  );
};

const Testimonials: EntryComponent<
  ILpComponentTestimonials,
  Awaited<ReturnType<typeof testimonialsLoader>>
> = {
  render: RenderTestimonials,
  loader: testimonialsLoader,
};

/* storybook-check-ignore */
export default Testimonials;
