import { css, keyframes } from '@emotion/react';

export const ANIMATION_DURATION = 0.7;
const revealAnimationKeyFrames = keyframes`
  0% {
    transform: translateY(100%) scale(90%);
    opacity: 0.9
    
  }
  100% {
    transform: translateY(0%) scale(100%);
    opacity: 1
  }
`;
export const REVEAL_ANIMATION = css({
  animation: `${revealAnimationKeyFrames} ${ANIMATION_DURATION}s`,
});

const hideAnimationKeyFrames = keyframes`
  0% {
    transform: translateY(0%) scale(100%);
    opacity: 1
    
  }
  100% {
    transform: translateY(200%) scale(90%);
    opacity: 0.9
  }
`;
export const HIDE_ANIMATION = css({
  animation: `${hideAnimationKeyFrames} ${ANIMATION_DURATION}s`,
});
