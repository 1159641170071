import { ParsedUrlQuery } from 'querystring';
import { useRouter } from 'next/router';

function useQueryParams(): ParsedUrlQuery {
  const router = useRouter();
  return router.query;
}

export function searchParamsFromUrl(urlOrPathname: string | undefined): URLSearchParams {
  if (!urlOrPathname) {
    return new URLSearchParams();
  }
  try {
    // http://localhost is a backup if urlOrPathname is an absolute URL
    const url = new URL(urlOrPathname, 'http://localhost');
    return url.searchParams;
  } catch (e) {
    return new URLSearchParams();
  }
}

export default useQueryParams;
