import * as React from 'react';

import LazyLoad from 'react-lazyload';

import imgixUrl from '../../helpers/imgixUrl';

export interface ImgixImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
}

export const imgixSrcs = (baseSrc?: string) => {
  const widths = [320, 480, 640, 1024];
  const src = baseSrc ? imgixUrl(baseSrc) : undefined;
  return [src, widths.map((w) => imgixUrl(`${baseSrc}?w=${w} ${w}w`)).join(', ')];
};

const ImgixImg: React.FC<Partial<ImgixImgProps>> = (props) => {
  const [src, srcSet] = imgixSrcs(props.src);
  return (
    <LazyLoad height={props.height}>
      <img alt="" {...props} src={src} srcSet={srcSet} />
    </LazyLoad>
  );
};

export default ImgixImg;
