import { ILpComponentMapBackgroundHero } from 'declarations/contentful';
import NextImage from 'next/image';
import { css, keyframes } from '@emotion/react';

import { Box, Link, Flex, Text, FlexProps } from '@opendoor/bricks/core';
import { EntryComponent } from 'cms/entries/entries';
import { imgixLoader } from '../../helpers/imgixUrl';
import Grid, { gridStyles } from './Grid';
import LazyLoad from 'react-lazyload';

const pulse = keyframes`
  0% {
    opacity: 1;
    transform: scale(0.95);
  }

  30% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    opacity: 0;
  }
`;

const MarkerHover = css({
  opacity: 0,
  transform: 'scale(1)',
  animation: `${pulse} 5s infinite`,
});

const MarkerHoverAlternate = css({
  opacity: 0,
  transform: 'scale(1)',
  animation: `${pulse} 4s infinite`,
});

const MarketAlternateTwo = css({
  opacity: 0,
  transform: 'scale(1)',
  animation: `${pulse} 5s infinite`,
});

const Marker = ({
  imageSource,
  animationDelay = '0s',
  ...props
}: { imageSource: string; animationDelay?: string } & FlexProps) => {
  return (
    <Box
      height={['40px', null, '50px']}
      width={['100px', null, '125px']}
      position="absolute"
      {...props}
      style={{
        animationDelay,
      }}
    >
      <Flex justifyContent="center" alignItems="center" width="100%">
        <NextImage
          layout="fill"
          loader={imgixLoader}
          src={imageSource}
          alt="Map marker for a home"
        />
      </Flex>
    </Box>
  );
};

const renderMapBackgroundHero = (entry: ILpComponentMapBackgroundHero) => {
  const { fields } = entry;

  return (
    <Flex
      position="relative"
      justifyContent="center"
      width="100%"
      minHeight={['680px', '480px', '700px']}
      alignItems="center"
      py={[6, null, 8]}
      mt="92px"
    >
      <Box zIndex={0} width="100%" height="100%" position="absolute" top="0" left="0" right="0">
        <LazyLoad offset={300}>
          <NextImage
            layout="fill"
            objectFit="cover"
            loader={imgixLoader}
            src={'2023-07-1689881024134-43912.jpg'}
            alt="Illustration of generic map"
          />
        </LazyLoad>
      </Box>
      <LazyLoad offset={300}>
        <Marker
          imageSource="2023-04-1682635416264-57298.png"
          left="10%"
          top={['17%', '12%', '12%']}
          css={MarkerHover}
          animationDelay="2s"
        />
      </LazyLoad>
      <LazyLoad offset={300}>
        <Marker
          imageSource="2023-04-1682635416269-85419.png"
          right={['7%', null, '30%']}
          top={['4%', null, '7%']}
          css={MarkerHoverAlternate}
          animationDelay="0.5s"
        />
      </LazyLoad>
      <LazyLoad offset={300}>
        <Marker
          imageSource="2023-04-1682635416260-76025.png"
          display={['none', null, 'block']}
          left="8%"
          top="40%"
          css={MarketAlternateTwo}
          animationDelay="0.5s"
        />
      </LazyLoad>
      <LazyLoad offset={300}>
        <Marker
          imageSource="2023-04-1682635416240-88834.png"
          left="15%"
          top={['80%', null, '85%']}
          css={MarketAlternateTwo}
        />
      </LazyLoad>
      <LazyLoad offset={300}>
        <Marker
          imageSource="2023-04-1682635416253-95450.png"
          right={['4%', null, '15%']}
          top={['90%', '85%', '60%']}
          css={MarkerHover}
          animationDelay="2.5s"
        />
      </LazyLoad>
      <Grid justifyContent="center" position="relative">
        <Flex
          gridColumn={gridStyles.colSpan12}
          justifyContent="center"
          zIndex={1}
          flexDir="column"
          alignItems="center"
        >
          <Box mb={[7]}>
            <Text fontWeight="medium" size="230" color="neutrals0">
              {fields?.title}
            </Text>
            <Text color="neutrals10" fontWeight="medium" size="230" opacity="65%">
              {fields?.secondaryTitle}
            </Text>
          </Box>
          <Flex
            width="100%"
            maxWidth={[null, '263px', null, '377px']}
            justifyContent="center"
            mb={[10]}
          >
            <Text textAlign="center" color="neutrals0" size="150">
              {fields?.description}
            </Text>
          </Flex>
          {fields?.ctaLink && fields?.ctaDisplayText && (
            <Link
              variant="primary-button"
              aria-label={fields?.ctaDisplayText}
              analyticsName="cosmos-landing-page-map-hero-cta"
              maxWidth="165px"
              padding="19px 25px"
              height="59px"
              href={fields?.ctaLink}
              style={{
                borderRadius: '14px',
                backgroundColor: '#2E69FF',
                fontWeight: '500',
              }}
            >
              {fields?.ctaDisplayText}
            </Link>
          )}
        </Flex>
      </Grid>
    </Flex>
  );
};

const MapBackgroundHero: EntryComponent<ILpComponentMapBackgroundHero> = {
  render: renderMapBackgroundHero,
};

export default MapBackgroundHero;
