import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

/** storybook-check-ignore */

export type StickyCTABarContextProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const StickyCTABarContext = React.createContext<StickyCTABarContextProps>({
  visible: false,
  setVisible: () => null,
});

export const useStickyCTABarContext = () => React.useContext(StickyCTABarContext);

export const StickyCTABarContextProvider: React.FC = ({ children }) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <StickyCTABarContext.Provider value={{ visible, setVisible }}>
      {children}
    </StickyCTABarContext.Provider>
  );
};

export const useStickyCTABarWhenRefNotVisible = () => {
  const { setVisible: setStickyVisible } = useStickyCTABarContext();

  const { ref, inView, entry: intersectionObserverEntry } = useInView();
  useEffect(() => {
    // for the first intersection observer event, the entry is undefined
    // as the element loads. We want to make sure not to flash the sticky
    // cta while it loads for the first time.
    if (!intersectionObserverEntry) {
      return;
    }
    setStickyVisible(!inView);
  }, [inView, setStickyVisible, intersectionObserverEntry]);

  return { ref };
};
