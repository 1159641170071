/* storybook-check-ignore */
import { ILpComponentHeroCard } from 'declarations/contentful';
import { Box, Flex, Image, Text } from '@opendoor/bricks/core';
import { EntryComponent } from 'cms/entries/entries';
import Grid, { gridStyles } from './Grid';
import styled from '@emotion/styled';

import { Parallax } from 'react-scroll-parallax';
import LazyLoad from 'react-lazyload';
import {
  generateDeprecatedImgixPictureSet,
  generateDeprecatedPictureSizeSet,
} from '../shared/performance/pictures';

const Container = styled(Flex)`
  @media (min-width: 865px) and (max-width: 992px) {
    min-height: 472px;
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    min-height: 580px;
  }
  @media (min-width: 1201px) and (max-width: 1440px) {
    min-height: 630px;
  }
  @media (min-width: 1440px) {
    min-height: 680px;
  }
`;

const CardNameText = styled(Text)`
  @media (max-width: 374px) {
    font-size: 16px;
  }
`;

const ClippedImageContainer = styled(Flex)`
  @media (min-width: 950px) and (max-width: 992px) {
    padding-right: 100px;
  }
`;

const HeroImageContainer = styled(Box)`
  @media (min-width: 992px) and (max-width: 1200px) {
    transform: translate(7%, 0px);
  }
  @media (min-width: 1201px) and (max-width: 1440px) {
    transform: translate(5%, 0);
  }
  @media (min-width: 1440px) {
    transform: translate(9%, 0);
  }
`;

const ParallaxBallons = () => {
  return (
    <Parallax speed={20}>
      <Image
        alt="parallax effect with balloons"
        src="https://imgdrop.imgix.net/2023-07-1689881604806-95436.png"
        loading="lazy"
        objectFit="contain"
        width="100%"
        height="auto"
      />
    </Parallax>
  );
};

const ParallaxVideoWalkthrough = () => {
  return (
    <Parallax speed={10}>
      <Image
        alt="parallax effect with video walkthrough desktop"
        src="https://imgdrop.imgix.net/2023-07-1689882596406-54963.jpeg"
        loading="lazy"
        objectFit="contain"
        width="100%"
        height="auto"
      />
    </Parallax>
  );
};

const renderHeroCard = (entry: ILpComponentHeroCard) => {
  const { fields } = entry;

  return (
    <LazyLoad height="100%" once offset={150}>
      <Grid>
        <Container
          gridColumn={gridStyles.colSpan12}
          style={{
            backgroundColor: '#F6F5F4',
            borderRadius: '36px',
          }}
          mb={[6, null, 7]}
          justifyContent={['space-between', null, 'space-between']}
          pl={[0, null, 10]}
          pt={[null, null, 10]}
          gap={[0, null, 8]}
          height={['472px', '596px', '396px', '592px']}
          flexDirection={['column', null, 'row']}
          alignItems={['center', null, 'flex-start']}
          overflow="hidden"
        >
          <Flex
            pt={['36px', '40px', 'unset', '32px']}
            px={['36px', '40px', 'unset', 0]}
            pb={[8, '54px', 10, '80px']}
            flexDir="column"
            justifyContent="space-between"
            width="100%"
            height={[null, null, '100%']}
            maxWidth={[null, null, '236px', '380px']}
          >
            <Box>
              <CardNameText m={0} fontWeight="medium" size="160" mb={[7, null, 6]}>
                {fields?.heroCardName}
              </CardNameText>
            </Box>
            <Text width={['100%', null, '100%']}>
              <Text as="span" fontWeight="medium" size="220" mt="3" mb={[6]}>
                {fields?.title + ' '}
              </Text>
              {fields?.lineBreakOnMobile && (
                <Box display={['block', 'none']} height="0">
                  <br />
                </Box>
              )}
              <Text
                as="span"
                my={3}
                style={{
                  color: '#7B7A79',
                }}
                size="220"
                fontWeight="medium"
              >
                {fields?.secondaryTitle}
              </Text>
            </Text>
          </Flex>
          <Box
            overflow="hidden"
            width="100%"
            height="100%"
            minHeight={['300px']}
            position="relative"
          >
            {fields?.clipImage ? (
              <ClippedImageContainer
                height="100%"
                justifyContent={['center', null, 'flex-end']}
                alignItems="end"
                pr={[null, null, '70px', '80px']}
              >
                <Flex
                  width={['100%', null, '80%']}
                  justifyContent={['center', null, 'flex-end']}
                  minWidth={[null, null, '286px', '495px']}
                  maxHeight={[null, '380px', 'unset']}
                  height="100%"
                  // @ts-expect-error  design isn't supported by token<radii>
                  borderRadius={'400.696px 400.696px 0px 0px'}
                  overflow="hidden"
                >
                  <video
                    // todo move this into contentful
                    src="https://odfil.es/static/videos/Section-hiw-step3-video.mp4"
                    autoPlay
                    muted
                    loop
                    playsInline
                  >
                    {/* fallback image */}
                    <Image
                      height="100%"
                      bottom="0"
                      objectFit="cover"
                      right="0"
                      alt=""
                      src={fields?.cardImage}
                      loading="lazy"
                    />
                  </video>
                  {fields.parallaxType === 'balloons' && <ParallaxBallons />}
                  {fields.parallaxType === 'video_walkthrough' && <ParallaxVideoWalkthrough />}
                </Flex>
              </ClippedImageContainer>
            ) : fields?.cardImage ? (
              <Box>
                <HeroImageContainer
                  position="absolute"
                  width="100%"
                  height="100%"
                  left="0"
                  display="block"
                  bottom="0"
                  alt=""
                  objectFit="cover"
                  objectPosition="50% 0%"
                  margin={0}
                >
                  {generateDeprecatedImgixPictureSet({
                    photoUrl: fields?.cardImage,
                    sizes: generateDeprecatedPictureSizeSet([
                      { w: 350, h: 280 },
                      { w: 380, h: 300 },
                      { w: 600, h: 480 },
                      { w: 780, h: 600 },
                      { w: 850, h: 667 },
                    ]),
                    debugImageSizes: false,
                    radii: {},
                    lazy: true,
                  })}
                </HeroImageContainer>
                {fields.parallaxType === 'balloons' && <ParallaxBallons />}
                {fields.parallaxType === 'video_walkthrough' && <ParallaxVideoWalkthrough />}
              </Box>
            ) : null}
          </Box>
        </Container>
      </Grid>
    </LazyLoad>
  );
};

const HeroCard: EntryComponent<ILpComponentHeroCard> = {
  render: renderHeroCard,
};

export default HeroCard;
