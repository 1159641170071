import { useState } from 'react';
import { ILpComponentCarouselCards } from 'declarations/contentful';
import { EntryComponent } from 'cms/entries/entries';
import { Text, Flex, Icon, Button } from '@opendoor/bricks/core';
import { useDevice } from '@opendoor/bricks/hooks/useMedia';
import Grid, { gridStyles } from '../Grid';
import { ReviewCard } from './ReviewCard';

import {
  ANIMATION_DURATION,
  disappearAnimation,
  slideAnimation,
  reverseDisappearAnimation,
  reverseSlideAnimation,
} from './CarouselAnimations';

const CARD_GAP = [5, null, 6, null, null];
const TITLE_MARGIN_LEFT = [6, null, 9, 11, null];
const CAROUSEL_MARGIN_LEFT = [6, null, 9, 11, 12];
const TITLE_TO_CAROUSEL_PADDING = [11, null, null, 0, null];
const TITLE_TO_BUTTONS_PADDING = [null, null, null, 11, null];

const RenderCarouselCards = (entry: ILpComponentCarouselCards) => {
  // Contentful Data
  const { fields } = entry;
  const reviews = fields?.reviews || [];

  const { isMobile } = useDevice();

  // Scrolling logic
  const [animate, setAnimate] = useState(false);
  const [numHiddenCards, setNumHiddenCards] = useState(0);
  const [scrollDirection, setScrollDirection] = useState<'next' | 'prev' | null>(null);

  const scrollCards = (direction: 'next' | 'prev') => {
    setAnimate(true);
    setScrollDirection(direction);
    if (direction === 'prev') {
      setNumHiddenCards(Math.max(numHiddenCards - 1, 0));
    }

    // Timeout has to match duration on animation CSS
    setTimeout(() => {
      setAnimate(false);

      if (direction === 'next') {
        setNumHiddenCards(Math.min(numHiddenCards + 1, reviews.length - 1));
      }
      setScrollDirection(null);
    }, ANIMATION_DURATION * 1000);
  };

  const getAnimation = (cardIdx: number) => {
    if (cardIdx == 0) {
      return scrollDirection === 'next' ? disappearAnimation : reverseDisappearAnimation;
    }
    return scrollDirection === 'next' ? slideAnimation : reverseSlideAnimation;
  };

  return (
    <Grid
      backgroundColor="neutrals0"
      py={[12, null, null, '160', null]}
      horizontalMargin={false}
      maxHeight={[null, null, null, '820']}
    >
      <Flex gridColumn={gridStyles.colSpan12} flexDirection={['column', null, null, 'row', null]}>
        <Flex
          flexDirection={[null, null, 'row', 'column', null]}
          justifyContent={['flex-start', null, null, 'center']}
          ml={TITLE_MARGIN_LEFT}
          paddingBottom={TITLE_TO_CAROUSEL_PADDING}
        >
          <Flex flexGrow={[null, null, 1, 'unset', null]} paddingBottom={TITLE_TO_BUTTONS_PADDING}>
            <Text
              minWidth={['257', '324', '509', '464', null]}
              maxWidth={['257', '324', '509', '464', null]} // using width={...} wasn't equivalent?
            >
              <Text as="span" color="brand50" size="170" fontWeight="medium">
                {`${fields?.title} `}
              </Text>
              <Text as="span" size="170" fontWeight="medium">
                {fields?.secondaryTitle}
              </Text>
            </Text>
          </Flex>
          {!isMobile && (
            <Flex
              mr={TITLE_MARGIN_LEFT}
              flexDirection={'column'}
              justifyContent={[null, null, 'flex-end', 'unset', 'unset']}
            >
              <Flex flexDirection="row">
                <Button
                  variant="icon"
                  mr={5}
                  onClick={() => scrollCards('prev')}
                  disabled={animate || numHiddenCards == 0}
                  aria-label="View previous review"
                  analyticsName="hp-view-previous-review"
                >
                  <Icon name="arrow-left" size={24} />
                </Button>
                <Button
                  variant="icon"
                  onClick={() => scrollCards('next')}
                  disabled={animate || numHiddenCards == reviews.length - 1}
                  aria-label="View next review"
                  analyticsName="hp-view-next-review"
                >
                  <Icon name="arrow-right" size={24} />
                </Button>
              </Flex>
            </Flex>
          )}
        </Flex>
        <Flex
          overflowX={['auto', null, 'hidden']}
          // hide scrollbar
          css={{
            '&::-webkit-scrollbar': { width: 0 },
          }}
          scrollSnapType={['inline mandatory', null, 'unset']}
          overscrollBehaviorX="contain"
          scrollPaddingLeft={[6, 6, 9, 0]} // ScrollPaddingRight wasn't working. Not sure why
          pr={[6, 6, 9, 0]} // hack for ScrollPaddingRight not working
          width="100%"
        >
          {reviews.map((review, idx) => {
            const reviewName = `hp-customer-review-${idx}`;
            const visibleCardIdx = idx - numHiddenCards;
            return (
              visibleCardIdx >= 0 && (
                <Flex
                  key={reviewName}
                  scrollSnapAlign="start"
                  ml={visibleCardIdx == 0 ? CAROUSEL_MARGIN_LEFT : CARD_GAP}
                  css={animate ? getAnimation(visibleCardIdx) : null}
                >
                  <ReviewCard
                    key={reviewName}
                    image={review?.fields?.image}
                    quote={review?.fields?.reviewerQuote}
                    link={review?.fields?.reviewLink}
                    name={review?.fields?.reviewerName}
                    market={review?.fields?.reviewerMarket}
                    analyticsName={reviewName}
                  />
                </Flex>
              )
            );
          })}
        </Flex>
      </Flex>
    </Grid>
  );
};

const CarouselCards: EntryComponent<ILpComponentCarouselCards> = {
  render: RenderCarouselCards,
};

export default CarouselCards;
