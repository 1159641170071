import { Global, css } from '@emotion/react';
import * as React from 'react';
import Head from 'next/head';
import { useObservability } from '../../helpers/observability';

import { Box, Text, Button } from '@opendoor/bricks/core';
import odometerCSS from '../shared/odometerCSS';
import useOdometer from '../shared/useOdometer';
import { ILpComponentOdometer } from 'declarations/contentful';
import { EntryComponent } from 'cms/entries/entries';

declare global {
  interface Window {
    odometerOptions: any;
    Odometer: any;
  }
}

const assetPrefix = 'https://cdn-cosmos.opendoor.com/js';

const RenderSocialProofOdometer = (entry: ILpComponentOdometer) => {
  const { fields } = entry;
  const message = fields.message;
  const body = fields.body;
  const buttonText = fields.buttonText;

  const odometerStart = 388456;
  const [inViewRef, inView, odometerRef] = useOdometer(odometerStart);
  const { trackEvent } = useObservability();

  const onClickFn = () => {
    // TODO: remove categoryOverride
    // observability is initialized with 'cosmos' as the category for all events
    trackEvent('cta-click', 'social-proof-odometer-get-started', undefined, {
      categoryOverride: 'homepage',
    });
    window.location.assign('/address-entry');
  };

  return (
    <React.Fragment>
      <Head>
        <script async src={`${assetPrefix}/odometer.min.js`} />
      </Head>
      <Global styles={css(odometerCSS)} />
      <Box
        as="section"
        id="social-proof-odometer-container"
        ref={inViewRef}
        mt={[11]}
        py={10}
        backgroundColor="blue30"
      >
        <Box
          width={['85%', null, 'initial', 'initial']}
          mx="auto"
          lineHeight="100"
          textAlign="center"
        >
          {inView && (
            <Box
              fontSize={['s5', null, 's8', 's8']}
              fontWeight="bold"
              color="brand50"
              lineHeight="100"
            >
              <Box ref={odometerRef} id="odometer" display="inline-block">
                {odometerStart}
              </Box>
              +
            </Box>
          )}
          <Text fontSize="s00" fontWeight="semibold" color="brand50" mb="3">
            {message}
          </Text>
          <Text mt={[3, 0]} color="neutrals100">
            {body}
          </Text>
          <Box width={['70%', null, '300px', '300px']} mx="auto" mt={[7, null, null, 8]}>
            <Button
              aria-label=""
              analyticsName="cosmos-landing-pages-socialProofOdometer-get-free-offer"
              /** @ts-expect-error TODO @growth Remove border radius override */
              borderRadius="rounded"
              size="lg"
              onClick={onClickFn}
            >
              {buttonText}
            </Button>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

const SocialProofOdometer: EntryComponent<ILpComponentOdometer> = {
  render: RenderSocialProofOdometer,
};

export default SocialProofOdometer;
