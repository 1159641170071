import React, { ReactNode } from 'react';
import { AccordionListItem, Box, Heading, List, Text } from '@opendoor/bricks/core';
import { useObservability } from '../../helpers/observability';
import styled from '@emotion/styled';
import { ILpComponentModal } from 'declarations/contentful';
import { EntryComponent } from 'cms/entries/entries';
import { Document } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import typography from '../../components/landing-pages/typography';
import SelectiveSpritesheet from '@opendoor/bricks/core/Icon/SelectiveSpritesheet';
import ChevronDown16 from '@opendoor/bricks/core/Icon/SpritesheetIcons/ChevronDown16';

const ANIMATION_DELAY = 0.3;

const Container = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 800px;
  position: relative;
  ::after {
    content: '';
    display: block;
    clear: both;
  }

  @media (max-width: 1199px) {
    max-width: 720px;
  }

  @media (max-width: 767px) {
    max-width: 90%;
  }
`;

interface FAQProps {
  title: string;
  panelId: string;
  body: ReactNode;
}

const RenderFAQs = (entry: ILpComponentModal) => {
  const { trackEvent } = useObservability();

  const { fields } = entry;
  const header = fields.header;
  const FAQLineItems = fields.lpComponentLineItem;

  const faqItems: Array<FAQProps> = [];

  if (FAQLineItems) {
    FAQLineItems.map((item) =>
      faqItems.push({
        title: item.fields.title as string,
        panelId: item.fields.panelId as string,
        body: documentToReactComponents(item.fields.body as Document),
      }),
    );
  }

  return (
    <Box as="section" pt={[10, null, 11, 11]} id="faq-container">
      <Container>
        <Heading
          size="UNSAFE_h4"
          as="h4"
          textAlign={['center', null, 'initial', 'initial']}
          mb={[7, null, null, 8]}
          {...typography.h4}
        >
          {header}
        </Heading>
        <Box mb={[8, null, null, 9]}>
          <SelectiveSpritesheet icons={[ChevronDown16]} />
          <List variant="accordion" bgColor="none" sx={{ li: { px: 4 } }}>
            {faqItems.map(({ body, title, ...faq }) => (
              <AccordionListItem
                {...faq}
                key={faq.panelId}
                analyticsName={`landing-pages-faq-${faq.panelId}`}
                wrapText={false}
                whiteSpace="normal"
                variant="secondary"
                title={
                  <Text fontWeight="bold" lineHeight="s1">
                    {title}
                  </Text>
                }
              >
                {React.Children.map(body, (faq) => (
                  <Text lineHeight="s1">{faq}</Text>
                ))}
              </AccordionListItem>
            ))}
          </List>
        </Box>
        <Text fontWeight="semibold" textAlign={['center', null, 'initial', 'initial']}>
          <a
            // TODO: remove categoryOverride
            // observability is initialized with 'cosmos' as the category for all events
            onClick={() =>
              trackEvent('cta-click', 'faq-see-all', undefined, { categoryOverride: 'homepage' })
            }
            href="https://www.opendoor.com/w/faq/"
            target="_blank"
            data-aos="fade-up"
            data-aos-delay={`${(faqItems.length + 1) * ANIMATION_DELAY}s`}
            rel="noreferrer"
          >
            See all FAQ
          </a>
        </Text>
      </Container>
    </Box>
  );
};

const FAQs: EntryComponent<ILpComponentModal> = {
  render: RenderFAQs,
};

export default FAQs;
