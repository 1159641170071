/* storybook-check-ignore */
import * as React from 'react';

// TODO: Label should not be used directly and should stay as an internal utility for bricks form components. Please update the usage of Label.
// eslint-disable-next-line no-restricted-imports
import { Label, LabelProps } from '@opendoor/bricks/core';
import { IAddressInputAddress } from '@opendoor/cinderblocks/growth/AddressInput/AddressInput';
import {
  AddressInput as CinderblocksAddressInput,
  IAddressInputProps,
} from '@opendoor/cinderblocks/growth/AddressInput';

import { startAddressVerification } from '../api/addressInputHelpers';
import { setCookie } from './trackers';
import { CONSUMER_FE_URL, GOOGLE_MAPS_API_KEY, COSMOS_URL } from '../globals';
import { trackImpression, trackInteraction } from '@opendoor/track/lib/tracker';
import { EventTrackingAction } from '@opendoor/observability/slim';
import { useObservability } from '../../helpers/observability';
import { CTAProps } from '@opendoor/bricks/complex/CtaInput';
import { OdProtosSellReceptionData_SellerInput_AnalyticsMetadata_Product } from '__generated__/athena';

import NewAddressInput, { IProps as NewAddressInputProps } from './NewAddressInput';
import useQueryParams from '../../helpers/queryParams';

export type { IAddressInputAddress };

type IAddressInputPropsWithoutCallbacks = Omit<
  IAddressInputProps,
  'onSubmit' | 'onChooseManualEntry' | 'googleMapsApiKey' | 'analyticsName'
>;
export interface SellerFlowAddressInputProps extends IAddressInputPropsWithoutCallbacks {
  trackingKeywords?: string;
  trackingTaxonomy?: string;
  inputLocation?: string;
  ctaProps: CTAProps;
  hideLabel?: boolean;
  // We only want to display above for sticky CTA mobile
  displayAbove?: boolean;
  placeholderText?: string;
  /**
   * Name of the address input in analytics events, should be unique within the app
   * Convention: {appName}-{addressInputName}
   */
  analyticsName: string;
  product: OdProtosSellReceptionData_SellerInput_AnalyticsMetadata_Product;
  /** Whether to show label in dark theme (white color) or not */
  labelProps?: LabelProps;
  forceNewAddressInput?: boolean;
  /**
   * Override the onSuccess callback to do something other than redirect to reception-fe
   */
  onSuccess?: (address: IAddressInputAddress, token: string, pdpUrl?: string) => void;
  /**
   * Override the onChooseManualEntry callback to do something other than redirect to reception-fe
   */
  onChooseManualEntry?: NewAddressInputProps['onChooseManualEntry'];
}

const onSubmit = async (
  address: IAddressInputAddress,
  trackEvent: ReturnType<typeof useObservability>['trackEvent'],
  product: OdProtosSellReceptionData_SellerInput_AnalyticsMetadata_Product,
  _impressionId?: string,
  trackingTaxonomy?: string,
  channel?: string,
  trackingKeywords?: string,
  inputLocation?: string,
  onSuccess?: SellerFlowAddressInputProps['onSuccess'],
) => {
  trackInteraction({ impressionId: _impressionId || 'none', type: 'select' });

  // Setting cookie to know where the customer is coming from in reception-fe for experiments
  if (trackingTaxonomy) {
    setCookie('sourceForTracking', trackingTaxonomy, 1);
  }

  const error = await startAddressVerification({
    address,
    product,
    channel,
    trackingKeywords,
    inputLocation,
    onSuccess: (token, pdpUrl) => {
      if (pdpUrl) {
        trackEvent('inputting_address_success' as EventTrackingAction, 'pdp-redirect');
        if (onSuccess) {
          return onSuccess(address, token, pdpUrl);
        }
        window.location.href = `${COSMOS_URL}${pdpUrl}?utm_source=seller&utm_medium=web&utm_campaign=ae_pdp`;
      } else {
        trackEvent('inputting_address_success' as EventTrackingAction, 'onboarding-redirect');
        if (onSuccess) {
          return onSuccess(address, token, pdpUrl);
        }
        window.location.href = `${CONSUMER_FE_URL}/seller-lead/${token}`;
      }
    },
  });
  if (error) {
    window.location.href = '/error';
  }
};

const startManualAddressVerification = (channel?: string, trackingKeywords?: string) => {
  const path = `${CONSUMER_FE_URL}/seller-lead/address-manual?type=new`;
  const searchParams = new URLSearchParams();
  if (channel) {
    searchParams.set('channel', channel);
  }
  if (trackingKeywords) {
    searchParams.set('keywords', trackingKeywords);
  }

  const params = searchParams.toString();
  window.location.assign(path + (params ? `?${params}` : ''));
};

export const SellerFlowAddressInput: React.FC<SellerFlowAddressInputProps> = ({
  className,
  inputLocation,
  trackingKeywords,
  trackingTaxonomy,
  channel,
  hideLabel,
  displayAbove,
  placeholderText,
  product,
  labelProps,
  onChooseManualEntry,
  onSuccess,
  showError = false,
  ...others
}) => {
  const impressionRef = React.useRef<string | undefined>();
  const { trackEvent } = useObservability();

  // New address input experiment
  const { new_address_input: newAddressInputVariant } = useQueryParams();
  const AddressInput =
    newAddressInputVariant === 'treatment' || others.forceNewAddressInput
      ? NewAddressInput
      : CinderblocksAddressInput;

  return (
    <>
      {!hideLabel && (
        <Label
          display="inline-block"
          mb="4"
          fontWeight="semibold"
          color="neutrals0"
          id="address-input-label"
          fontSize="s0"
          htmlFor="address-input"
          {...labelProps}
        >
          Enter your home address
        </Label>
      )}
      <AddressInput
        ariaLabelledby="address-input-label"
        aria-label="Enter your home address"
        showError={showError}
        className={className}
        onInputReady={() => {
          impressionRef.current = trackImpression({
            elementId: 'bricks:address-input',
            interactable: true,
            location: inputLocation,
            type: 'ready',
          }).impressionId;
        }}
        placeholderText={placeholderText ?? '125 W Muriel Dr'}
        onSubmit={(address: IAddressInputAddress) => {
          onSubmit(
            address,
            trackEvent,
            product,
            impressionRef.current,
            trackingTaxonomy,
            channel,
            trackingKeywords,
            inputLocation,
            onSuccess,
          );
        }}
        onChooseManualEntry={
          onChooseManualEntry ||
          function () {
            return startManualAddressVerification(channel, trackingKeywords);
          }
        }
        onValidate={(isValid) => {
          if (isValid) {
            return;
          }
          // onValidate is called if we get a correct address back from google
          // but then the place_id verification fails, example is:
          // 1673 Sunburst St, Northridge, CA, USA
          onChooseManualEntry
            ? onChooseManualEntry(channel)
            : startManualAddressVerification(channel, trackingKeywords);
        }}
        googleMapsApiKey={GOOGLE_MAPS_API_KEY}
        channel={channel}
        optionsDisplayProps={{ displayAbove: displayAbove }}
        {...others}
      />
    </>
  );
};
