/* storybook-check-ignore */
import { Image } from '@opendoor/bricks/core';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '@opendoor/bricks/theme/eero';

import ImgixImg from '../shared/ImgixImg';
import { ILpComponentPress } from 'declarations/contentful';
import { Awaited, EntryComponent } from 'cms/entries/entries';
import { Asset, Entry } from 'contentful';
import { proxyContentfulImage } from '../../helpers/landingPages';

type PressImage = {
  index: number;
  imageUrl: string;
};

export type IPressLoaderReturn = {
  pressItemImages?: PressImage[];
};

const pressLoader = async (input: ILpComponentPress, _root?: Entry<any>) => {
  const pressItems = input.fields.pressItems;
  const pressItemImages = pressItems?.map((item, index): PressImage => {
    // edgecase for social proof press
    if (!item.fields.image) {
      return { index, imageUrl: '' };
    }
    const imageUrl = proxyContentfulImage(item.fields.image as Asset);
    return { index, imageUrl };
  });

  const loaderReturn: IPressLoaderReturn = {
    pressItemImages: pressItemImages || [],
  };
  return loaderReturn;
};

const PressItem = styled.div`
  width: 200px;
  text-align: center;
  margin: 0 30px;

  @media (max-width: 1100px) {
    width: 100px;
    margin: 0 15px;
  }

  @media (max-width: 767px) {
    margin: 0;
    width: 200px;
    text-align: left;
    margin-right: 30px;
  }
`;

const Section = styled.section`
  position: relative;
  display: flex;
  padding: 50px 50px 0 50px;
  justify-content: space-between;
  background-color: ${colors.neutrals0};

  @media (max-width: 1100px) {
    padding: 30px 30px 0 30px;
  }

  @media (max-width: 767px) {
    overflow-x: scroll;
    padding: 20px 20px 0 20px;
    margin-right: 20px;
    & {
      .reviews-io {
        order: 1;
      }
      .press-1 {
        order: 2;
      }
      .press-2 {
        order: 3;
      }
      .press-3 {
        order: 4;
      }
      .press-4 {
        order: 5;
      }
      .press-5 {
        order: 6;
      }
      .press-6 {
        order: 7;
      }
    }
  }
`;

const Quote = styled.blockquote`
  margin: 0 0 20px;
  padding: 0;
  font-size: 14px;
  color: ${colors.neutrals90};
  line-height: 1.2;
  margin-top: 20px;
  border: 0;

  @media (max-width: 1100px) {
    width: 100px;
    font-size: 13px;
    margin: 10px 0 0 0;
  }

  @media (max-width: 767px) {
    width: 140px;
    font-size: 11px;
    margin: 10px 0 0 0;
  }
`;

const imgCss = css`
  @media (max-width: 1100px) {
    height: 16px;
  }

  @media (max-width: 767px) {
    height: 16px;
  }
`;

const Stars = styled.img`
  margin-top: 15px;

  @media (max-width: 1100px) {
    height: 16px;
  }

  @media (max-width: 767px) {
    height: 16px;
  }
`;

const Rating = styled.p`
  font-size: 12px; // .text-small
  color: ${colors.neutrals90};
`;

const RenderPress = (
  entry: ILpComponentPress,
  resolvedData?: Awaited<ReturnType<typeof pressLoader>>,
) => {
  const { fields } = entry;

  let rating: string;
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    rating = require('../../../data/rating.json')['average_rating'];
  } catch (err) {
    // something is wrong with fetch rating, default 4.5
    rating = '4.5';
  }

  const pressItems = fields.pressItems;
  const pressItemImages = resolvedData?.pressItemImages;
  const pressItemComponents = pressItems?.map((item, index) => {
    const imageUrl = pressItemImages?.find((image) => image.index === index)?.imageUrl;
    const quoteCite = item.fields.quoteCite;
    const quote = item.fields.quote;
    const imageAltText = item.fields.imageAltText;
    const className = `press-${index + 1}`;

    // Reviews.io is a custom PressItem, so we need to handle it separately
    if (imageAltText === 'Reviews.io') {
      return (
        <PressItem
          key={imageAltText}
          className={'reviews-io'}
          css={css`
            // this is required, even though it isn't applied on desktop
            // it seems to trick emotion into thinking that this is a different class
            // (or my nth-of-type is wrong)
            order: 0;
          `}
        >
          <ImgixImg
            css={imgCss}
            src="320adb9c154840c99dad5e0772fc5d86.jpg"
            alt="Reviews.io"
            height={30}
          />
          <Stars
            src="https://imgdrop.imgix.net/150b8d8c42c7471a9281fc1297150827.svg"
            alt="4.5/5 stars rating"
            height={24}
          />
          <Rating>{rating}/5</Rating>
        </PressItem>
      );
    }

    return (
      <PressItem key={imageAltText} className={className}>
        <Image css={imgCss} src={imageUrl} alt={imageAltText || ''} height={30} />
        <Quote cite={quoteCite}>{quote}</Quote>
      </PressItem>
    );
  });
  return <Section>{pressItemComponents}</Section>;
};

const Press: EntryComponent<ILpComponentPress, Awaited<ReturnType<typeof pressLoader>>> = {
  render: RenderPress,
  loader: pressLoader,
};

export default Press;
